<template>
  <component :is="elementType" class="flex flex-row flex-nowrap items-center h-full" :class="effectiveLinkClasses" :href="href">
    <div v-if="params && params.icon && label" class="inline-block w-5 h-5 mr-1 flex-shrink-0" @click="click">
      <yb-icon :icon="params.icon" />
    </div>
    <span :class="effectiveLabelClasses" @click="click">{{ label }}</span>
  </component>
</template>

<script>
export default {
  props: {
    linkClasses: {
      type: [String, Array],
      default: 'yb-link'
    },
    labelClasses: {
      type: String,
      default: 'font-semibold'
    }
  },
  computed: {
    label () {
      return this.params.value
    },
    effectiveLabelClasses() {
      return this.params.labelClasses || this.labelClasses
    },
    effectiveLinkClasses() {
      return this.params.linkClasses || this.linkClasses
    },
    href() {
      if (this.params && typeof this.params.href === 'function') {
        return this.params.href(this.params.data)
      } else {
        return this.params.href || '#'
      }
    },
    elementType() {
      return this.params.href ? 'a' : 'div'
    }
  },
  methods: {
    click ($event) {
      if (this.params.href && $event && ($event.metaKey || $event.ctrlKey)) {
        return // href is controlled by user with meta or ctrl key
      }
      if (this.params.clicked) {
        $event.preventDefault()
        $event.stopPropagation()
        this.params.clicked(this.params.data, $event)
      }
    }
  }
}
</script>
