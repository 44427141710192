import { Duration } from 'luxon'

import YbGridLink from './YbGridLink.vue'
import {
  humanizeMs,
  humanizeNumber,
  formatNumber,
  formatDateTime,
  formatDateTimeMs,
  bytes
} from '@/utils/filters'

// Make the component constructors for our cell renderers and export them
const YbGridLinkCellRenderer = defineComponent(YbGridLink)
export { YbGridLinkCellRenderer }

export function isEmpty(params) {
  return !params ||
        params.value === null ||
        params.value === undefined ||
        typeof params.value === 'undefined'
}

export const paramsToOptions = (params) => {
  let options = null
  if (params.timeZone) {
    options = { timeZone: params.timeZone }
  }
  return options
}

export function YbGridDurationCellRenderer(params) {
  if (isEmpty(params)) {
    return ''
  }
  return humanizeMs(params.value)
}

export function YbGridTimestampCellRenderer(params) {
  if (isEmpty(params)) {
    return ''
  }
  return formatDateTime(params.value, null, null, paramsToOptions(params))
}

export function YbGridTimestampMsCellRenderer(params) {
  if (isEmpty(params)) {
    return ''
  }
  return formatDateTimeMs(params.value, paramsToOptions(params))
}

export function YbGridTimestampUsCellRenderer(params) {
  if (isEmpty(params)) {
    return ''
  }
  const us = (params.value * 1000) % 1000 // Assuming [millis].[micros] format
  return formatDateTimeMs(params.value, paramsToOptions(params)) + ` [${us}]`
}

export function YbGridInteger(params, precision) {
  if (isEmpty(params)) {
    return ''
  }
  return formatNumber(params.value, typeof precision === 'undefined' ? 0 : precision)
}

export function YbGridNumber(params, precision) {
  if (isEmpty(params)) {
    return ''
  }
  return formatNumber(params.value, typeof precision === 'undefined' ? 9 : precision)
}

export function YbGridHumanizeInteger(params, precision) {
  if (isEmpty(params)) {
    return ''
  }
  return humanizeNumber(params.value, typeof precision === 'undefined' ? 0 : precision)
}

export function YbGridHumanizeNumber(params, precision) {
  if (isEmpty(params)) {
    return ''
  }
  return humanizeNumber(params.value, typeof precision === 'undefined' ? 0 : precision)
}

export function YbGridBytes(params) {
  if (isEmpty(params)) {
    return ''
  }
  return bytes(params.value)
}

export function YbGridBytesEmpty(params) {
  if (isEmpty(params)) {
    return ''
  }
  return bytes(params.value)
}

export function YbGridBoolean(params) {
  if (isEmpty(params)) {
    return ''
  }
  return params.value ? '&check;' : '&times;'
}

export function YbGridIntervalCellRenderer(params) {
  if (isEmpty(params)) {
    return ''
  }
  const interval = Duration.fromISO(params.value)
  if (!interval) {
    return ''
  }
  return humanizeMs(interval.toMillis(), true)
}

export default function () {
  // no-op
}
